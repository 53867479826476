import http from '../../http';

//获取单条记录数据
export function getListOrInfo (params) {
    return http({
        url: "/api/user/salaryExportList",
        method: "get",
        params:params
    })
}

//导入工资
export function uploadSalary(data) {
    return http({
        url: "/api/user/uploadPayrollExcel",
        method: "post",
        data:data
    })
}

//修改工资单
export function editSalaryOne (data) {
    return http({
        url: "/api/reportStats/salaryStats",
        method: "post",
        data:data
    })
}
//生成工资单
export function createSalaryStats (data) {
    return http({
        url: "/api/reportStats/salaryStats",
        method: "put",
        data:data
    })
}


//发送工资单
export function putSalaryStats (data) {
    return http({
        url: "/api/reportStats/sendSalaryStats",
        method: "post",
        data:data
    })
}
