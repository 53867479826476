<template>
  <div v-loading="loading">
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>工资单报表</span>
      </div>
      <div>
        <!--横等于20000显示-->
        <el-button
            type="primary"
            @click="createSalaryFunc"
        >
          生成工资单
        </el-button>
      </div>
    </div>
    <div style="display: flex;">
      <el-date-picker
          v-model="time"
          type="month"
          style="margin-bottom: 15px"
          @change="initLoad"
          value-format="timestamp"
          placeholder="选择月">
      </el-date-picker>

      <el-upload
          action=""
          :before-upload="uploadBtn">
        <el-button type="" style="width: 100px; margin-left: 10px">导入</el-button>
      </el-upload>
      <el-button type="" style="width: 100px; margin-left: 10px; margin-bottom: 15px" @click="downExl">导出</el-button>
      <el-button type="" style="width: 100px; margin-left: 10px; margin-bottom: 15px" @click="downMode">下载导入模板</el-button>
    </div>

    <!--统计表-->
    <common-table
        :tableData="totalSalaryData"
        :tableProp="totalTableProp"
        style="margin-bottom: 15px;"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @handleSizeChange="handleSizeChange"
        @pageCurrentChange="pageCurrentChange"
        @send="send"
    />

  </div>


</template>

<script>
import {
  getListOrInfo,
  putSalaryStats,
  editSalaryOne,
  createSalaryStats,
  uploadSalary
} from '@/api/module/salary/function'

export default {
  data() {    // eslint-disable-next-line vue/no-dupe-keys
    return {
      headersData:{Authorization: 'Bearer ' + localStorage.getItem('admin_token')}, //上传图片的token
      uploadFileUrl: process.env.VUE_APP_URL + '/api/user/uploadPayrollExcel',
      time:'',
      y_m:'',
      //table 数据源
      totalTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'is_send',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },

        table: [{
          title: '工资合计',
          field: 'salary',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '绩效合计',
          field: 'jixiao',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '提成/分红合计',
          field: 'red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '津贴合计',
          field: 'subsidy_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '其他奖励合计',
          field: 'other_allowance',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '扣款合计',
          field: 'deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '应发工资合计',
          field: 'wages_payable',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '基本养老保险费合计',
          field: 're_old',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '基本医疗保险费合计',
          field: 're_health',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '失业保险费合计',
          field: 're_lose',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '大病保险合计',
          field: 'sickness_insurance',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '住房公积金合计',
          field: 're_public',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '其他扣除合计',
          field: 'other_deductions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计收入合计',
          field: 'cumulative_income',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计专项扣除合计',
          field: 'accumulated_special_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计子女教育扣除合计',
          field: 'c_c_edu_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计住房贷款利息扣除合计',
          field: 'a_houe_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计住房租金支出扣除合计',
          field: 'a_h_r_e_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计赡养老人扣除合计',
          field: 'd_a_elderly_support',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计继续教育支出扣除合计',
          field: 'd_a_c_edu_expenditure',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计专项附加扣除合计',
          field: 'a_special_a_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计其他扣除合计',
          field: 'a_o_deductions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计应缴个税合计',
          field: 'a_i_i_t_payable',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计已缴个税合计',
          field: 'a_i_i_t_paid',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '本月应缴个税合计',
          field: 're_tax',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '实发工资合计',
          field: 'Issued',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
      },
      //表格数据
      totalSalaryData: [],
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'is_send',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          type: 2,
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },

        table: [{
          title: '月份',
          field: 'month',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '部门',
          field: 'user_station',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name:'department_name',
        },{
          title: '职员',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name:'name',
        }, {
          title: '工资',
          field: 'salary',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '绩效',
          field: 'jixiao',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '提成/分红',
          field: 'red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '津贴',
          field: 'subsidy_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '其他奖励',
          field: 'other_allowance',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '扣款',
          field: 'deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '应发工资',
          field: 'wages_payable',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '基本养老保险费',
          field: 're_old',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '基本医疗保险费',
          field: 're_health',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '失业保险费',
          field: 're_lose',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '大病保险',
          field: 'sickness_insurance',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '住房公积金',
          field: 're_public',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '其他扣除',
          field: 'other_deductions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计收入',
          field: 'cumulative_income',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计专项扣除',
          field: 'accumulated_special_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计子女教育扣除',
          field: 'c_c_edu_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计住房贷款利息扣除',
          field: 'a_houe_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计住房租金支出扣除',
          field: 'a_h_r_e_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计赡养老人扣除',
          field: 'd_a_elderly_support',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计继续教育支出扣除',
          field: 'd_a_c_edu_expenditure',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计专项附加扣除合计',
          field: 'a_special_a_deduction',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计其他扣除',
          field: 'a_o_deductions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计应缴个税',
          field: 'a_i_i_t_payable',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '累计已缴个税',
          field: 'a_i_i_t_paid',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '本月应缴个税',
          field: 're_tax',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '实发工资',
          field: 'Issued',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '员工签领',
          field: 'status_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '发送',
            type: 'primary',
            method: 'send',
            status: '0',
            id: 20000,
          }],
        }],
      },
      //表格数据
      tableData: [],
      loading:true,
      dialogVisible: {
        active: false
      },
      timeScope: '',
      tabStatus: 0,
      userlist: [],
      isPage: true, //是否分页
      page: {
        current_page: 1,
        total: 0,
        size: 10,
        method: 'pageCurrentChange',
      },
      // statsData
    }
  },
  created() {
    this.timeScope = new Date(new Date().toLocaleDateString()).getTime() - 31 * 24 * 3600 * 1000;
    this.time = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()
    this.initLoad();
  },
  methods: {
    changeDay(day, row) {
      row.work_day = Math.ceil(day)
      this.sendSalaryStats(row)
    },

    //生成工资单
    createSalaryFunc() {
      let data = {}
      createSalaryStats(data).then(res => {
        this.$message({
          type: 'success',
          message: res.msg
        });
        this.initLoad();
      })
    },
    total(row) {
      let yingkou = parseFloat(row.re_take_off)
      yingkou += parseFloat(row.re_work_off)
      yingkou += parseFloat(row.re_tax)
      yingkou += parseFloat(row.re_public)
      yingkou += parseFloat(row.re_health)
      yingkou += parseFloat(row.re_lose)
      yingkou += parseFloat(row.re_old)
      yingkou += parseFloat(row.re_born)
      yingkou += parseFloat(row.re_injury)
      let yingfa = parseFloat(row.red_price) + parseFloat(row.subsidy_price) + parseFloat(row.salary) + parseFloat(row.jixiao) + parseFloat(row.other_allowance);


      row.yingkou = yingkou
      row.yingfa = yingfa
      row.totalSalary = 0;
      row.totalSalary += parseFloat(row.yingfa);//应发
      row.totalSalary -= parseFloat(row.yingkou);//应扣
      row.totalSalary += parseFloat(row.reissue);//补发
      this.sendSalaryStats(row)
    },

    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    open() {
      this.$refs.form.$emit("open")
    },
    // 分页切换
    CurrentChange(val) {
      this.page.current_page = val;
      this.initLoad()
    },

    initLoad() {
      console.log(this.time)
      this.$store.state.loading = true;
      this.loading=true;
      let params = {
        page: this.tableProp.page.current_page,
        per_page: this.tableProp.page.size,
        y_m: Math.trunc(this.time/1000)
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getListOrInfo(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = Number(res.per_page);
        for (let i = 0; i < res.data.length; i++) {

          res.data[i].status == 1 ? res.data[i].status_name = '已确认' : res.data[i].status_name = '未确认'

        }
        this.tableData = res.data

        this.totalSalaryData=[];
        let totalSalaryDataJson = res.statsData
        this.totalSalaryData.push(totalSalaryDataJson)
        this.loading=false;
      })
    },
    send(row) {
      this.$confirm('此操作会发送该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        putSalaryStats(data).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.initLoad()
        })
      })
    },
    sendSalaryStats(row) {
      // this.$confirm('此操作保存该记录, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //
      // })
      row.re_take_off = row.re_take_off.toString()
      row.re_work_off = row.re_work_off.toString()
      console.log(row);
      // return false
      editSalaryOne(row).then(res => {
        console.log(res)
        this.$message({
          type: 'success',
          message: res.msg
        });

      })
    },

    //导入文件
    uploadBtn(file){
      console.log(this.time)
      this.y_m = Math.trunc(JSON.parse(JSON.stringify(this.time))/1000)
      console.log(this.y_m)

      let formData = new FormData()
      formData.append('file', file)
      formData.append('y_m', Math.trunc(JSON.parse(JSON.stringify(this.time))/1000))
      uploadSalary(formData).then(()=>{
        this.$message.warning('导入成功!');
        this.initLoad()
      })
      return false
    },
    //导出文件
    downExl(){
      let url = window.location.origin
      console.log(url)
      window.open(url + '/api/user/salaryExport?y_m=' + Math.trunc(this.time/1000))
      // downloadSalary({y_m: Math.trunc(this.y_m/1000)}).then((res)=>{
      //   console.log(res)
      // })
    },
    //下载模板
    downMode(){
      window.open('http://testhc.hc-info.cn/storage/template/salary.xlsx')
      // downloadSalary({y_m: Math.trunc(this.y_m/1000)}).then((res)=>{
      //   console.log(res)
      // })
    },
    //table 分页 点击当前页
    handleSizeChange(val) {
      console.log(val)
      this.tableProp.page.size = val
      this.initLoad();
    },
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
  },
  mounted() {
    this.$on('open', function () {
      this.dialogVisible = true
    });
  }

}
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }

  .content {
    padding: 40px;
  }
}

.paginationBox {
  padding-top: 20px;
  text-align: right;
}


</style>
